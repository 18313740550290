import React, {useState, useMemo} from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import Home from "./pages/home/home";
import Bands from "./pages/bands/bands";
import Shop from "./pages/shop/shop";
import Verein from "./pages/verein/verein";
import Sponsoren from "./pages/sponsoren/sponsoren";
import Xmas from "./pages/xmas/xmas";
import Impressum from "./pages/impressum/impressum";
import {AGB} from "./pages/impressum/agb";
import Faq from "./pages/faq/faq";
import Rules from "./pages/rules/rules";
import Kalender from "./pages/Kalender/Kalender";

import { ArticleContext, CartContext } from './components/context';
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import Article from './components/article/article';
import Warenkorb from './components/cart/cart';
import Snowfall from 'react-snowfall'

import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

export default function App() {
  const [articleData, setArticleData] = useState([])  
  const [cartValue, setCartValue] = useState([]);
  const cartContextValue = useMemo(
    () => ({ cartValue, setCartValue }), 
    [cartValue]
  );
  const articleContextValue = useMemo(
    () => ({ articleData, setArticleData }), 
    [articleData]
  );
  return (
    <ArticleContext.Provider value={articleContextValue}>
      <CartContext.Provider value={cartContextValue}>
    <Router>
      <div className="App">
        <Snowfall />
        <ReactNotifications />
        <Header /> 
        <div className="main">
        <Routes>
          <Route exact path="/" element={<Home/>}/>

          <Route exact path="/history/*" element={<Bands history/>}/>
          <Route exact path="/bands/*" element={<Bands/>}/>
          <Route exact path="/akustik/*" element={<Bands akustik/>}/>
          <Route exact path="/shop/*" element={<Shop/>}>
            <Route exact path="artikel/*" element={<Article/>}/>
            <Route exact path="warenkorb" element={<Warenkorb/>}/>
          </Route>          
          <Route exact path="/sponsoren" element={<Sponsoren/>}/>          
          <Route exact path="/verein" element={<Verein/>}/>
          <Route exact path="/xmas" element={<Xmas/>}/>
          <Route exact path="/impressum" element={<Impressum/>}/> 
          <Route exact path="/agb" element={<AGB/>}/> 
          <Route exact path="/faq" element={<Faq/>}/> 
          <Route exact path="/rules" element={<Rules/>}/> 
          <Route exact path="/kalender" element={<Kalender/>}/> 

        </Routes>
        </div>
        <Footer />
      </div>
    </Router>
        </CartContext.Provider>
      </ArticleContext.Provider>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
